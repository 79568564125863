/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import stores from '../../stores';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SideNav, SideNavHeading, SideNavItem } from '@react/react-spectrum/SideNav';
import Button from '@react/react-spectrum/Button';
import Close from '@react/react-spectrum/Icon/Close';
import { Menu, MenuItem } from '@react/react-spectrum/Menu';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import OverflowIcon from 'dc-icons/Sign-DesignAssets/authoring/S_Overflow_18.svg';
import PowerAutomateIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/Power_Automate-16x.svg';
import { isMedia, media } from '../../utils/mediaQuery';

// spectrum-SideNav-heading
const StyledHeader = styled(SideNavHeading)`
  padding-bottom: 8px;

  & .spectrum-SideNav-heading {
    margin-top: 6px;
  }
`;

// override Sign global styling (see screen.less in monolith)
const StyledSideNav = styled(SideNav)`
  & a:hover,
  a:focus {
    text-decoration: none;
  }
`;

const StyledSideNavItem = styled(SideNavItem)`
  & .spectrum-SideNav-itemLink.is-selected:not(.focus-ring)::before {
    border-bottom-left-radius: 3px;
    border-left: 2px solid #2680eb;
    border-top-left-radius: 3px;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
  }
`;

const StyledCloseButton = styled(Button)`
  float: right;
`;

/*
  Class Names for Nav Links:
    "state_waiting_for_others_link", "state_waiting_for_you_link", "state_completed_link",
    "state_cancelled_link", "state_expired_link", "state_archive_link", "state_draft_link",
    "agreement_type_template_link", "agreement_type_webform_link", "agreement_type_megasign_link"
 */
const getClassName = labelKey => {
  return `${labelKey.replace(/\./g, '_')}_link`;
};

const LOCAL_STORAGE_KEY_FOR_IN_CONTEXT_ACTIONS = 'showPowerAutomateInContextActions';

const isPowerAutomateInContextActionsVisible = () => {
  return (
    stores.Floodgate.hasPowerAutomateInContextActionsV1Enabled() &&
    window.localStorage.getItem(LOCAL_STORAGE_KEY_FOR_IN_CONTEXT_ACTIONS) === 'true'
  );
};

const onArchivalClick = () => {
  window.location.href = window.location.origin + '/account/advancedWorkflow?category=Archival#/workflowTemplates';
};

const onNotificationsClick = () => {
  window.location.href = window.location.origin + '/account/advancedWorkflow?category=Notification#/workflowTemplates';
};

const inContextActionsView = (node, intl) => {
  const showPowerAutomateInContextActions = isPowerAutomateInContextActionsVisible();
  const isWaitingForOthers = node?.value === 'state.waiting_for_others';
  const isCompleted = node?.value === 'state.completed';

  if ((!isWaitingForOthers && !isCompleted) || !showPowerAutomateInContextActions) {
    return null;
  }

  const KEYS = {
    UP: 'ArrowUp',
    DOWN: 'ArrowDown'
  };

  const keyDownEvent = e => {
    if (e && [KEYS.UP, KEYS.DOWN].includes(e.key || e.code)) {
      document.getElementsByClassName('in-context-menu-item')[0].focus();
    }
  };

  const localizedAutomateNotifications = intl.formatMessage({ id: 'in_context_actions.automate.notifications' });
  const localizedAutomateArchival = intl.formatMessage({ id: 'in_context_actions.automate.archival' });

  const inContextActionsNotificationsMenuItemView = (
    <MenuItem
      id="in-context-notifications-option"
      className="in-context-menu-item"
      icon={<PowerAutomateIcon style={{ marginRight: '10px' }} />}
      value="automateNotifications"
      onClick={onNotificationsClick}
      aria-label={localizedAutomateNotifications}
    >
      {localizedAutomateNotifications}
    </MenuItem>
  );

  const inContextActionsArchivalMenuItemView = (
    <MenuItem
      id="in-context-archival-option"
      className="in-context-menu-item"
      icon={<PowerAutomateIcon style={{ marginRight: '10px' }} />}
      value="automateArchival"
      onClick={onArchivalClick}
      aria-label={localizedAutomateArchival}
    >
      {localizedAutomateArchival}
    </MenuItem>
  );

  return (
    <OverlayTrigger
      trapFocus
      defaultShow={false}
      delayHide={100}
      crossOffset={70}
      placement="bottom"
      trigger={['hover', 'click', 'focus']}
      style={{ position: 'absolute', right: '0px' }}
    >
      <Button id={'in-context-action-button'} variant="tool" onKeyDown={e => keyDownEvent(e)} icon={<OverflowIcon />} />
      <Menu>
        {isWaitingForOthers ? inContextActionsNotificationsMenuItemView : inContextActionsArchivalMenuItemView}
      </Menu>
    </OverlayTrigger>
  );
};

const ManageNav = ({ value, data, intl, close }) => {
  const generateSubTree = node => {
    const localizedValue = intl.formatMessage({ id: node.labelKey });
    const countMessage = node.count >= 0 ? ` (${node.count})` : '';
    if (!node.children) {
      return (
        <StyledSideNavItem
          className={getClassName(node.labelKey)}
          key={node.labelKey}
          value={node.value}
          label={`${localizedValue}${countMessage}`}
          onSelect={node.onSelect}
          icon={inContextActionsView(node, intl)}
        />
      );
    }
    if (node.isHeader) {
      return (
        <StyledHeader key={node.labelKey} label={`${localizedValue}${countMessage}`}>
          {node.children.map(childNode => generateSubTree(childNode))}
        </StyledHeader>
      );
    }
    return (
      <StyledSideNavItem
        className={getClassName(node.labelKey)}
        key={node.labelKey}
        expanded={true}
        value={node.value}
        label={`${localizedValue}${countMessage}`}
        onSelect={node.onSelect}
      >
        {node.children.map(childNode => generateSubTree(childNode))}
      </StyledSideNavItem>
    );
  };

  if (!data) {
    return null;
  }
  return isMedia(media.phone) ? (
    <StyledSideNav autoFocus value={value}>
      <StyledCloseButton
        variant="tool"
        icon={<Close size="S" />}
        onClick={close}
        aria-label={intl.formatMessage({ id: 'sidenav.close_button.aria_label' })}
      />
      {data.map(node => generateSubTree(node))}
    </StyledSideNav>
  ) : (
    <StyledSideNav value={value}>{data.map(node => generateSubTree(node))}</StyledSideNav>
  );
};

ManageNav.propTypes = {
  value: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  close: PropTypes.func.isRequired,
  intl: PropTypes.shape.isRequired
};

ManageNav.defaultProps = {};

// export the component with the localizations injected
export default injectIntl(ManageNav);
