import { providers } from 'dc-core';
import Env from './env';

/**
 * get query param floodgate feature flag override
 *
 *    ?features=foo,!bar   --> enable foo, disable bar; * to enable all
 *
 * @param flag {string} a flag name
 * @return {boolean|null} override value if available, otherwise null = no override
 *
 * @see managepage-static readme --
 */
const loc = new URL(window.location);
const overrides = (loc.searchParams.get('features') || '').split(',');
const allFeatures = overrides.includes('*');
const noFeatures = overrides.includes('!*');

const getOverride = function(flag) {
  // do we have any + or - overrides
  const pos = overrides.includes(flag),
    neg = overrides.includes('!' + flag),
    hasOverride = pos || neg || allFeatures || noFeatures;

  if (!hasOverride) {
    return null;
  }
  return (pos && !noFeatures) || (allFeatures && !neg);
};

/**
 * Floodgate Helper class
 *
 * Assume Floodgate is already instantiated by the containing app with
 * correct cliend ID and Api end-point.
 *
 * Main methods:
 *
 *   this.ready() -- initialize floodgate, returning a promise
 *   this.hasX() -- helper function to determine if flag X exists
 *
 * @constructor
 */
class Floodgate {
  // fallback if floodgate is not available
  provider = {
    hasFlag(f) {
      console.warn('Floodgate not available: ', f);
      return false;
    }
  };

  /**
   * initialized and return promise
   *
   * @return {Promise<Floodgate>}
   */
  ready() {
    return providers.discovery().then(() => {
      if (Env.isGovCloud) {
        // Floodgate is not available in govcloud. Skip initialization and assume all flags disabled.
        return this;
      }

      return providers.floodgate().then(provider => {
        this.provider = provider;
        return this; // return this so caller has access to methods
      });
    });
  }

  /**
   * @private
   */
  hasFlag(flag) {
    const override = getOverride(flag);
    return override === null ? this.provider.hasFlag(flag) : override;
  }

  hasJpTagEnabled() {
    return this.hasFlag('dc-web-jp-tag');
  }

  hasPowerAutomateInContextActionsV1Enabled() {
    return this.hasFlag('dc-power-automate-in-context-actions-v1-enabled');
  }

  hasSettingsSearchApiEnabled() {
    return this.hasFlag('dc-sign-context-settings-search-api');
  }
}

export default Floodgate;
