import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import memoize from 'memoize-one';
import { TableView } from '@react/react-spectrum/TableView';
import _ from 'lodash/core';
import _range from 'lodash/range';
import stores from '../../stores';

import PlainTableCell from './Cells/PlainTableCell';
import SenderTableCell from './Cells/SenderTableCell';
import DateTableCell from './Cells/DateTableCell';
import StatusTableCell from './Cells/StatusTableCell';
import ActionTableCell from './Cells/ActionTableCell';
import EndDateConfirmedCell from './Cells/EndDateConfirmedCell';
import EndDateCalendarCell from './Cells/EndDateCalendarCell';
import TitleTableCell from './Cells/TitleTableCell';
import IconTableCell from './Cells/IconTableCell';
import RecipientTableCell from './Cells/RecipientTableCell';
import ContextActionsCell from './Cells/ContextActionsTableCell';
import SkeletonLoader from './Skeleton';
import bindAndCatchAndThrow from '../../utils/bindAndCatchAndThrow';

import NavUtil from '../../utils/NavUtil';
import GroupsUtil from '../../utils/GroupsUtil';
import { analyticsFor } from '../../utils/analytics';
import ContextActionUtil from '../../utils/ContextActionUtil';
import { sanitizeDate } from '../../utils/StringUtil';
import { getMaxSelectionCount } from '../../model/datasource/SignatureItemsDataSource';
import CompositeTableCell from './Cells/CompositeTableCell';
import FilterHelper from '../../utils/FilterHelper';
import CompositeActionCell from './Cells/CompositeActionCell';
import ShowDetailsCell from './Cells/ShowDetailsCell';

const ANIMATION_DURATION_BULK = 50;
const ANIMATION_DURATION_DEFAULT = 500; // @see node_modules/@react/collection-view/src/CollectionView.js

// React Spectrum Hack: Get component aria-colindex attribute based on column name
const getAriaColIndex = (columns, type, allowMultiSelect) => {
  const HIDDEN_CHX_IDX = allowMultiSelect ? 0 : 1;
  const foundIndex = columns.findIndex(column => type === column);
  return foundIndex !== -1 ? foundIndex + HIDDEN_CHX_IDX + 1 : foundIndex;
};

// React Spectrum Hack: Show and Hide minimum vs full column views using CSS
const getShowColumnsDirectives = (cfg, allowMultiSelect) => {
  let columns = cfg.useMin ? cfg.columns.MIN : cfg.columns.FULL;
  if (!cfg.sharedAgreementsView || !!cfg.userShare) {
    columns = columns.filter(c => c !== 'user_name');
  }
  if (!cfg.actionPermitted) {
    columns = columns.filter(c => c !== 'action');
  }
  if (!cfg.useMin) {
    const compositeCols = [
      'composite_participant',
      'composite_action',
      'composite_name',
      'composite_name_status_date',
      'composite_resource_status_date'
    ];
    columns = columns.filter(c => !compositeCols.includes(c));
  }
  const notCols = columns
    .filter(c => !['context_actions'].includes(c))
    .reduce((memo, column) => {
      const ariaColIdx = getAriaColIndex(cfg.columns.FULL, column, allowMultiSelect);
      return memo + `:not([aria-colindex="${ariaColIdx}"])`;
    }, '');
  return `.spectrum-Table-cell${notCols},.spectrum-Table-headCell${notCols}{ display: none; }`;
};

const getInlineActionsDirectives = (cfg, allowMultiSelect) => {
  const columns = cfg.useMin ? cfg.columns.MIN : cfg.columns.FULL;
  if (columns.includes('context_actions')) {
    const ariaColIdx = getAriaColIndex(cfg.columns.FULL, 'context_actions', allowMultiSelect);
    return `& .react-spectrum-TableView-row {
      .spectrum-Table-cell[aria-colindex="${ariaColIdx}"] {
        position: absolute;
        right: 0;
        top: 0;
      }
      &:hover {
        .spectrum-Table-cell[aria-colindex="${ariaColIdx}"] { display:flex !important; }
      }
    }`;
  }
};

const getShowDetailsDirectives = (cfg, allowMultiSelect) => {
  const columns = cfg.useMin ? cfg.columns.MIN : cfg.columns.FULL;
  if (columns.includes('show_details')) {
    const ariaColIdx = getAriaColIndex(cfg.columns.FULL, 'show_details', allowMultiSelect);
    return `& .react-spectrum-TableView-row {
      .spectrum-Table-cell[aria-colindex="${ariaColIdx}"] {
        display:flex !important;
        position: absolute;
      }
      .spectrum-Table-headCell[aria-colindex="${ariaColIdx}"] {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
    }`;
  }
};

// Function to show inline action.
// Eventually this will be renamed to getInlineActionsDirective to replace context actions
const getQuickGlanceInlineActionsDirectives = (cfg, useInlineActions) => {
  let styleDirectives;
  if (!cfg.useMin && useInlineActions) {
    styleDirectives = `& .react-spectrum-TableView-row {
      &:hover, &.is-focused {
        .hover-button {
          display:flex !important;
          visibility: visible !important;
        }
        .pinned-button {
          background-color: #fafafa;
          border-color: #e1e1e1;
          color: #4b4b4b;
        }
      }
    }`;
  }
  return styleDirectives;
};

const getNameDirectives = (cfg, allowMultiSelect) => {
  const ariaColIdx = getAriaColIndex(cfg.columns.FULL, 'name', allowMultiSelect);
  return `& .react-spectrum-TableView-row {
      .spectrum-Table-cell[aria-colindex="${ariaColIdx}"], .spectrum-Table-headCell[aria-colindex="${ariaColIdx}"] {
        flex-grow: 2 !important;
      }
    }`;
};

const TableViewWithCustomColumns = styled(TableView)`
  & .react-spectrum-TableView-row.is-focused.focus-ring::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #2680eb;
    border-radius: 4px;
  }

  &.react-spectrum-TableView .react-spectrum-TableView-body > div:nth-child(2) {
    opacity: 1 !important;
  }

  /* If at max selection, disabled unchecked checkboxes */
  &.react-spectrum-TableView.at-max-selection {
    .react-spectrum-TableView-body {
      div.item:not(.selected) {
        .spectrum-Table-checkbox {
          opacity: 0.3;
        }
      }
    }
  }
  ${props => getShowColumnsDirectives(props.columnsToDisplay, props.allowsMultipleSelection)}
  ${props => getInlineActionsDirectives(props.columnsToDisplay, props.allowsMultipleSelection)}
  ${props => getQuickGlanceInlineActionsDirectives(props.columnsToDisplay, props.useInlineActions)}
  ${props => getShowDetailsDirectives(props.columnsToDisplay, props.allowsMultipleSelection)}
  ${props => getNameDirectives(props.columnsToDisplay, props.allowsMultipleSelection)}
`;

const DefaultSkeletonTableCell = () => <SkeletonLoader width="100px" />;
const DefaultSkeletonTableCellForTextWithInfo = () => (
  <div>
    <SkeletonLoader width="100px" />
    <InfoSkeletonContainer>
      <SkeletonLoader width="50px" />
    </InfoSkeletonContainer>
  </div>
);

const DateSkeletonContainer = styled.div`
  align: right;
  width: 100%;
`;

const InfoSkeletonContainer = styled.div`
  font-size: 11px;
`;

const hardCodedColumns = {
  active_participant: {
    sortable: true,
    minWidth: 180,
    maxWidth: 300
  },
  sender: {
    sortable: true,
    minWidth: 140,
    maxWidth: 300
  },
  name: {
    sortable: true,
    minWidth: 300
  },
  modify_date: {
    sortable: true,
    sortKey: 'last_transaction_date',
    minWidth: 150,
    maxWidth: 200,
    align: 'right'
  },
  termination_dates: {
    sortable: true,
    minWidth: 150,
    align: 'right'
  },
  termination_dates_confirmed: {
    sortable: false,
    minWidth: 150,
    align: 'center'
  },
  termination_dates_calendar: {
    sortable: false,
    minWidth: 50,
    maxWidth: 65,
    align: 'center'
  },
  state: {
    sortable: true,
    minWidth: 140,
    maxWidth: 200
  },
  user_name: {
    sortable: true,
    minWidth: 140,
    maxWidth: 300
  },
  action: {
    sortable: true,
    sortKey: 'state',
    minWidth: 140,
    maxWidth: 200
  },
  icon: {
    sortable: false,
    minWidth: 50,
    maxWidth: 60
  },
  show_details: {
    sortable: false
  },
  context_actions: {
    sortable: false
  },
  group_id_set: {
    sortable: false,
    minWidth: 140,
    maxWidth: 300
  }
};

// Takes Spectrum Ranges objects and expands and flattens them into one array.
// If asString is given, returns human readable string.
const getSelectionRanges = (indexPaths, asString) => {
  const selection = indexPaths && indexPaths.sectionIndexSets.get(0);
  if (!selection) {
    return asString ? '' : [];
  }
  const res = _.flatten(
    selection.ranges.map(({ start, end }) => {
      return asString ? (start === end ? start : `${start}-${end}`) : _range(start, 1 + end);
    })
  );
  return asString ? res.join() : res;
};

const getModifiedDateForRecord = data =>
  // For library templates we want to continue to use modify_date.
  // Fall back on the modify date field if last_transaction_date isn't available
  data.agreement_type === 'LIBRARY_TEMPLATE' ? data.modify_date : data.last_transaction_date || data.modify_date;

@injectIntl
class SignTable extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.renderCell = this.renderCell.bind(this);
    this.tableRef = React.createRef();
    this.deSelectedRowIndex = -1;
    this.notCheckboxClick = false;
  }

  getModifyDate(data) {
    const date = getModifiedDateForRecord(data);
    return date ? sanitizeDate(this.props.intl.formatDate(date)) : null;
  }

  getSender(data, showSharedBy) {
    const { intl } = this.props;
    const me = intl.formatMessage({ id: 'tablecell.me' });
    const hasNoSenderType = ['LIBRARY_TEMPLATE', 'WIDGET'].includes(data.agreement_type);
    const sender = hasNoSenderType ? null : showSharedBy ? data.user_name : data.sender || me;
    return sender ? intl.formatMessage({ id: 'tablecell.sender' }, { name: sender }) : null;
  }

  getParticipationInfo(data) {
    const { intl } = this.props;
    const company = data.active_participant_org;
    const total = data.participation_count;
    const completedCount = data.participation_completion_count;
    return total > 1 ? intl.formatMessage({ id: 'x_of_total_completed' }, { x: completedCount, total }) : company;
  }

  getRecipient(recipient) {
    return recipient ? stores.Api.Utils.trimSmsDomain(recipient) : null;
  }

  getSharedByInfo(sharedBy) {
    const { intl } = this.props;
    return sharedBy ? intl.formatMessage({ id: 'tablecell.sharedBy' }, { name: sharedBy }) : null;
  }

  getStatus(status, isSharedAgreement) {
    const { intl } = this.props;
    const messageId = NavUtil.labelKeyForStatus(`state.${status.toLowerCase()}`, isSharedAgreement);
    return intl.formatMessage({ id: messageId });
  }

  getResourceTypeInfo(type, sharedBy) {
    const { intl } = this.props;
    const typeInfo = type ? intl.formatMessage({ id: `tablecell.${FilterHelper.findGroupNameBy(type)}.name` }) : null;
    const sharedByInfo = sharedBy ? intl.formatMessage({ id: 'tablecell.sharedBy' }, { name: sharedBy }) : null;
    const info =
      typeInfo && sharedByInfo
        ? intl.formatMessage({ id: 'tablecell.type.sharedBy' }, { type: typeInfo, name: sharedBy })
        : null;
    return info || typeInfo || sharedByInfo;
  }

  getReminders(data) {
    const { intl } = this.props;
    const hasActive = !!data.active_reminder_count;
    const hasCompleted = !!data.completed_reminder_count;
    let reminder;

    const activeRemindStr = count => {
      return count === 1
        ? intl.formatMessage({ id: 'action.remind.scheduled.tooltip' })
        : intl.formatMessage({ id: 'action.remind.scheduled_multiple.tooltip' }, { count: count });
    };

    const completedRemindStr = count => {
      return count === 1
        ? intl.formatMessage({ id: 'action.remind.sent.tooltip' })
        : intl.formatMessage({ id: 'action.remind.sent_multiple.tooltip' }, { count: count });
    };

    if (hasActive) {
      reminder = activeRemindStr(data.active_reminder_count);
    }
    if (hasCompleted) {
      reminder = completedRemindStr(data.completed_reminder_count);
    }
    if (hasActive && hasCompleted) {
      reminder = activeRemindStr(data.active_reminder_count);
    }
    return reminder;
  }

  getNotes(data) {
    const MAX_CHARS = 20;
    const notes = data.note;
    return !!notes && notes.length > MAX_CHARS ? `${notes.substring(0, MAX_CHARS)} ...` : notes;
  }

  @bindAndCatchAndThrow
  handleActionCellClick(index) {
    const column = Object.assign({}, hardCodedColumns.action, { key: 'action' });
    this.props.onCellAction(column, index);
  }

  @bindAndCatchAndThrow
  handleShowDetailsClick(index) {
    this.props.onCellAction({ key: 'show_details' }, index);
  }

  @bindAndCatchAndThrow
  handleEndDataCellClick(index) {
    this.props.onCellAction({ key: 'termination_dates_confirmed' }, index);
  }

  @bindAndCatchAndThrow
  handleEndDataCalendarCellClick(index) {
    this.props.onCellAction({ key: 'termination_dates_calendar' }, index);
  }

  @bindAndCatchAndThrow
  handleSortChange(descriptor) {
    this.props.sendAnalytics(analyticsFor.COLUMN_SORT, {
      column: descriptor.column.key,
      direction: descriptor.direction
    });

    this.props.onSortChange(descriptor.column.key, descriptor.direction === -1 ? 'asc' : 'desc');
  }

  // loading skeleton if data contains 'useSkeleton' flag
  renderCell(column, data, rowFocused) {
    const { columnsToDisplay: config } = this.props;
    const isSharedAgreement = config.sharedAgreementsView || config.switchAccountView;
    const isSharerSender = !data.sender;
    const type = this.props.showAgreementType ? data.agreement_type : undefined;
    if (data.useSkeleton) {
      return this.renderSkeletonCell(column);
    }

    const me = config.user;
    const primaryAction = ContextActionUtil.getPrimaryAction(data, isSharedAgreement, this.props.isCsvExportEnabled);
    const actionHandler = (action, openInNewTab) =>
      this.props.scbAction(action, data.agreement_id, data.agreement_type.toLowerCase(), data.user_id, openInNewTab);

    const actions = [];

    //BulkSend child agreements button on the parent
    if (data.agreement_type === 'MEGASIGN_PARENT' && column.key === 'name') {
      actions.push({
        id: 'action.child_agreements',
        name: 'child_agreements',
        onClick: () => {
          this.props.onCellAction({ key: 'show_child_agreements' }, this.props.dataSource.sections[0].indexOf(data));
        }
      });
    } else {
      actions.push({
        id: 'action.view',
        name: 'view',
        onClick: actionHandler
      });
    }

    actions.push({
      id: ContextActionUtil.getActionLabelId(primaryAction, data.state),
      name: primaryAction,
      onClick: actionHandler,
      data: primaryAction === 'remind' ? this.getReminders(data) : undefined
    });

    // Check for ability to add note
    if (ContextActionUtil.canAddNote(data, me)) {
      actions.push({
        id: 'action.notes',
        name: 'notes',
        onClick: actionHandler,
        data: this.getNotes(data)
      });
    }

    if (column.key === 'icon') {
      return <IconTableCell />;
    }
    if (column.key === 'group_id_set') {
      // Only show group for agreements where user has originator role
      const isTemplate = data.agreement_type === 'LIBRARY_TEMPLATE';
      const showGroup = data.roles.includes('ORIGINATOR');
      const groupName = isTemplate
        ? GroupsUtil.getTemplateGroupName(data.shared_with_ids)
        : GroupsUtil.getGroupName(data.group_id_set, isSharedAgreement);
      return <PlainTableCell className="group-table-cell">{(showGroup && groupName) || '\u2014'}</PlainTableCell>;
    }
    if (column.key === 'sender') {
      const hasNoSenderType = ['LIBRARY_TEMPLATE', 'WIDGET'].includes(data.agreement_type);
      // No sender info for library templates and web forms
      const sender = hasNoSenderType
        ? '\u2014'
        : isSharedAgreement && isSharerSender
        ? data.user_name
        : data[column.key] || this.props.intl.formatMessage({ id: 'tablecell.me' });
      const company = hasNoSenderType
        ? undefined
        : isSharedAgreement && isSharerSender
        ? data.user_organization
        : data.sender_organization;
      return <SenderTableCell name={sender} company={company} />;
    }
    if (column.key === 'modify_date') {
      return <DateTableCell date={getModifiedDateForRecord(data)} />;
    }
    if (column.key === 'termination_dates') {
      return <DateTableCell date={data[column.key][0]} />;
    }
    if (column.key === 'termination_dates_confirmed') {
      return (
        <EndDateConfirmedCell
          date={data.termination_dates[0]}
          isConfirmed={data[column.key]}
          onClick={this.handleEndDataCellClick}
          rowHasFocus={rowFocused}
          rowIndex={this.props.dataSource.sections[0].indexOf(data)}
        />
      );
    }
    if (column.key === 'termination_dates_calendar') {
      return (
        <EndDateCalendarCell
          date={data.termination_dates[0]}
          onClick={this.handleEndDataCalendarCellClick}
          rowHasFocus={rowFocused}
          rowIndex={this.props.dataSource.sections[0].indexOf(data)}
        />
      );
    }
    if (column.key === 'state') {
      return (
        <StatusTableCell
          messageId={NavUtil.labelKeyForStatus(`state.${data[column.key].toLowerCase()}`, isSharedAgreement)}
          // flag to display visual clue next to the agreement status if UNRESOLVED_BOUNCE_INDICATOR_FEATURE_AVAILABLE = true
          // for the originator and the agreement has a bounced event
          isUnresolvedBounceAvailable={this.props.unresolvedBounceAvailable}
          data={data}
          isSharedAgreement={config.sharedAgreementsView}
        />
      );
    }
    if (column.key === 'action') {
      return (
        <ActionTableCell
          id={`${this.props.dataSource.sections[0].indexOf(data)}`}
          status={data.state}
          hasFocus={rowFocused}
          onClick={this.handleActionCellClick}
        />
      );
    }
    if (column.key === 'show_details') {
      const index = this.props.dataSource.sections[0].indexOf(data);
      const selection = this.props.dataSource.collection.selectedIndexPaths;
      const isSelected = getSelectionRanges(selection).includes(index);
      return (
        <ShowDetailsCell
          onClick={this.handleShowDetailsClick}
          rowData={{
            index,
            isFocused: rowFocused,
            isSelected,
            isMultiSelected: selection.length > 1
          }}
        />
      );
    }
    if (column.key === 'name') {
      const confirmedEndDate =
        data.roles.includes('ORIGINATOR') && data.termination_dates_confirmed && data.termination_dates
          ? data.termination_dates[0]
          : '';
      return (
        <TitleTableCell
          title={data[column.key]}
          error={data.lastError}
          type={type}
          sharedBy={data.sharer}
          endDate={this.props.showConfirmedEndDates && confirmedEndDate}
          isProtected={data.is_password_protected}
          actions={this.props.useInlineActions ? actions : []}
        />
      );
    }
    if (column.key === 'active_participant') {
      return (
        <RecipientTableCell
          recipient={this.getRecipient(data.active_participant)}
          company={data.active_participant_org}
          total={data.participation_count}
          completedCount={data.participation_completion_count}
          actions={
            this.props.useInlineActions && config.drillDownView && data.agreement_type === 'WIDGET_INSTANCE'
              ? actions
              : []
          }
        />
      );
    }
    if (column.key === 'context_actions') {
      return (
        <ContextActionsCell
          primaryAction={primaryAction}
          primaryActionLabelId={ContextActionUtil.getActionLabelId(primaryAction, data.state)}
          onClick={(action, openInNewTab) => {
            this.props.scbAction(
              action,
              data.agreement_id,
              data.agreement_type.toLowerCase(),
              data.user_id,
              openInNewTab
            );
          }}
        />
      );
    }

    if (column.key === 'composite_participant') {
      const showSharer = isSharedAgreement && isSharerSender;
      const info = this.getParticipationInfo(data);
      const sender = this.getSender(data, showSharer);
      return (
        <CompositeTableCell
          className="composite-participant"
          primary={this.getRecipient(data.active_participant)}
          secondary={info ? [info] : null}
          tertiary={sender ? [sender] : null}
        />
      );
    }

    // Usage: Agreement ( Completed, Cancelled, Expired, archive, draft ), template, webform, megasign parent
    // Displays: Title, Shared by, Modify date
    // No Status information
    if (column.key === 'composite_name') {
      const title = data.name;
      const info = this.getResourceTypeInfo(type, data.sharer);
      return (
        <CompositeTableCell
          className="composite-name"
          primary={title}
          secondary={info ? [info] : null}
          tertiary={[this.getModifyDate(data)]}
          error={data.lastError}
        />
      );
    }

    // Usage: Agreement (waiting for you)
    // Displays: Title, Action
    if (column.key === 'composite_action') {
      return (
        <CompositeActionCell
          id={`${this.props.dataSource.sections[0].indexOf(data)}`}
          name={data.name}
          status={data.state}
          hasFocus={rowFocused}
          onClick={this.handleActionCellClick}
        />
      );
    }

    // Usage: Agreement (waiting for others)
    // Displays: Title, Status, Modify Date
    if (column.key === 'composite_name_status_date') {
      const title = data.name;
      const status = this.getStatus(data.state, isSharedAgreement);
      const sharedBy = this.getSharedByInfo(data.sharer);
      const info = [status, sharedBy].filter(txt => !!txt);
      return (
        <CompositeTableCell
          className="composite-name"
          primary={title}
          secondary={info}
          tertiary={[this.getModifyDate(data)]}
          error={data.lastError}
          isBounced={this.props.unresolvedBounceAvailable && data.has_unresolved_bounce}
        />
      );
    }

    // Usage: All search
    // Displays: Title, Resource Name, Status, Modify Date
    if (column.key === 'composite_resource_status_date') {
      const title = data.name;
      const typeInfo = this.getResourceTypeInfo(type, data.sharer);
      const status = this.getStatus(data.state, isSharedAgreement);
      const date = this.getModifyDate(data);
      const secondaryInfo = [typeInfo].filter(txt => !!txt);
      const tertiaryInfo = [status, date].filter(txt => !!txt);
      return (
        <CompositeTableCell
          className="composite-name"
          primary={title}
          secondary={secondaryInfo}
          tertiary={tertiaryInfo}
          error={data.lastError}
        />
      );
    }
    return <PlainTableCell className={`${column.key}-table-cell`}>{data[column.key]}</PlainTableCell>;
  }

  renderSkeletonCell(column) {
    if (column.key === 'icon') {
      return <SkeletonLoader width="32px" height="32px" widthRandomness="0" />;
    }
    if (column.key === 'sender') {
      return <DefaultSkeletonTableCellForTextWithInfo />;
    }
    if (['modify_date', 'termination_dates'].includes(column.key)) {
      return (
        <DateSkeletonContainer>
          <DefaultSkeletonTableCell />
        </DateSkeletonContainer>
      );
    }
    if (column.key === 'name') {
      return <SkeletonLoader width="150px" />;
    }
    if (column.key === 'active_participant') {
      return <DefaultSkeletonTableCellForTextWithInfo />;
    }
    return <DefaultSkeletonTableCell />;
  }

  // Memoize mapped columns to avoid creating new objects on each render
  mapColumns = memoize((intl, columns) =>
    columns
      .filter(columnKey => columnKey !== 'checkbox')
      .map(columnKey => {
        const title =
          columnKey === 'show_details'
            ? intl.formatMessage({ id: 'show_details.link' })
            : ['icon', 'context_actions', 'termination_dates_calendar', 'termination_dates_confirmed'].includes(
                columnKey
              )
            ? ''
            : intl.formatMessage({ id: `tableheader.${columnKey}` });

        return {
          ...hardCodedColumns[columnKey],
          title,
          key: columnKey
        };
      })
  );

  get tableEl() {
    return (this._tableEl = this._tableEl || ReactDOM.findDOMNode(this.tableView));
  }

  expandColumns() {
    const { intl, columnsToRender } = this.props;
    return this.mapColumns(intl, columnsToRender);
  }

  updateSelectionStatus(selectedIndexPaths) {
    const numSelected = selectedIndexPaths && selectedIndexPaths.length;
    this.atMaxSelection = numSelected >= getMaxSelectionCount();

    // Remove data errors when deselecting all
    if (!numSelected && this.lastSelectedIndexPaths) {
      this.props.dataSource.clearDataErrors(getSelectionRanges(this.lastSelectedIndexPaths));
    }

    this.lastSelectedIndexPaths = selectedIndexPaths;

    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(selectedIndexPaths);
    }
    if (!this.tableEl) {
      return;
    }

    const classes = { add: [], remove: [] };
    classes[this.atMaxSelection ? 'add' : 'remove'].push('at-max-selection');
    const classList = this.tableEl.classList;
    if (classes.add.length) {
      classList.add.apply(classList, classes.add);
    }
    if (classes.remove.length) {
      classList.remove.apply(classList, classes.remove);
    }
  }

  componentDidMount() {
    this.tableView = this.tableRef.current;

    // Allow data source access to collection to determine selection state
    this.props.dataSource.collection = this.tableView.collection;

    // implement Sign custom multiselect behavior
    if (this.props.allowMultiSelect) {
      this.implementMaxSelection();
      this.implementFunkyMultiSelect();
    }
  }

  componentWillUnmount() {
    this.tableView.collection.offEvent('mouseDown');
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // When we have a state change (i.e., status tab changes), reset
    // any existing row index so that a spurious deselect event isn't fired.
    if (nextProps.navTabId !== this.props.navTabId) {
      this.deSelectedRowIndex = -1;
    }
    return true;
  }

  implementFunkyMultiSelect() {
    const origSelectItem = this.tableView.collection.selectItem;
    const self = this;

    // override EditableCollectionView.js
    this.tableView.collection.selectItem = function(indexPath, toggle, extend, emit) {
      self.itemClicked = indexPath;
      self.prevNumSelected = this.selectedIndexPaths.length;
      origSelectItem.apply(this, arguments);
    };

    // Cell clicks can take two paths:
    //   1. Checkbox cell: onCheckboxClick() -> collection.selectItem() (TableRow.js))
    //   2. Other cells: onMouseDown() -> collection.selectItem()  (EditableCollectionView.js)
    // We override collection.selectItem() and need to know which path it took.
    // So add an event listener.
    this.tableView.collection.onEvent('mouseDown', () => {
      this.notCheckboxClick = true;
    });
  }

  implementMaxSelection() {
    // handle limited select all
    const origSelectall = this.tableView.collection.selectAll;
    this.tableView.collection.selectAll = function() {
      const numRows = this.delegate.getItemsInSection(0).length;
      const numSelected = this.selectedIndexPaths.length;
      const maxSelection = getMaxSelectionCount();
      if (numRows < maxSelection) {
        return origSelectall.apply(this);
      } else if (!this.canSelectItems) {
        return;
      } else if (numSelected === maxSelection) {
        // we're already at full selection - clear it
        this.clearSelection();
      } else {
        const selection = this._selection;
        selection.clear();
        const start = selection.content.firstIndexPath;
        if (start) {
          const end = start.copy();
          end.index = maxSelection - 1; // index is 0-based
          selection._addRange(start, end);
          selection.anchor = start.copy();
          selection.current = end.copy();
        }
        this._updateSelection();
      }
    };

    // handle shift-click selection
    const origAddRangeInSection = this.tableView.collection._selection.addRangeInSection;

    // Flow:
    // EditableCollectionView::selectItem()
    //   -> Selection::extendTo()
    //     -> Selection::_addRange()
    //     -> IndexPathSet::addRangeInSection()
    // 'this' below is Selection.
    this.tableView.collection._selection.addRangeInSection = function(section, range) {
      const collection = this.content.dataSource.collection;
      const numSelected = collection.selectedIndexPaths.length;
      const maxSelection = getMaxSelectionCount();
      const numToAdd = 1 + range.end - range.start; // inclusive at both ends
      const numOver = numToAdd + numSelected - maxSelection;
      if (numOver > 0) {
        range.end -= numOver;
      }
      return origAddRangeInSection.apply(this, arguments);
    };
  }

  // If row is clicked and there is no previous selection, deselect it.
  // NOTE: this is called after selection count has been updated.
  onCellClick(column, rowIndex) {
    if (!this.itemClicked || !this.notCheckboxClick) {
      return;
    }
    let emit = false;
    if (this.tableView.collection.selectedIndexPaths.length === 1 && this.prevNumSelected <= 1) {
      // emit an event (update selection) so that context board is removed
      // when clicking on same row again.
      emit = this.deSelectedRowIndex === rowIndex;
      this.props.dataSource.deselect(rowIndex, emit);
    }
    this.deSelectedRowIndex = emit ? -1 : rowIndex;
    this.itemClicked = null;
    this.notCheckboxClick = false;
  }

  render() {
    const { className, dataSource, onCellDoubleClick, columnsToDisplay, allowMultiSelect = false } = this.props;
    const columns = this.expandColumns();
    const isBulk =
      this.tableView && this.tableView.collection && this.tableView.collection.selectedIndexPaths.length > 1;

    // onCellClick doesn't get called when clicked on a column that doesn't
    // get rendered again (i.e., min mode).  So call it manually if it wasn't.
    if (this.itemClicked) {
      this.onCellClick(null, this.itemClicked.index);
    }

    return (
      <TableViewWithCustomColumns
        className={className}
        allowsSelection={true}
        allowsMultipleSelection={allowMultiSelect}
        columns={columns}
        dataSource={dataSource}
        renderCell={this.renderCell}
        onSelectionChange={this.updateSelectionStatus.bind(this)}
        onCellClick={this.onCellClick.bind(this)}
        onCellDoubleClick={onCellDoubleClick}
        ref={this.tableRef}
        columnsToDisplay={columnsToDisplay}
        rowHeight={this.props.rowHeight}
        quiet={this.props.useQuietTableVariant}
        sortDescriptor={{
          column: columns.find(column => column.key === this.props.sortColumn) || {},
          direction: this.props.sortDirection === 'asc' ? -1 : 1
        }}
        onSortChange={this.handleSortChange}
        // Reduce animation duration in case of bulk actions.
        transitionDuration={isBulk ? ANIMATION_DURATION_BULK : ANIMATION_DURATION_DEFAULT}
        useInlineActions={this.props.useInlineActions}
      />
    );
  }
}

SignTable.propTypes = {
  onSelectionChange: PropTypes.func.isRequired,
  onCellDoubleClick: PropTypes.func.isRequired,
  onCellAction: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  useInlineActions: PropTypes.bool,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string
};

export default SignTable;

export {
  getShowColumnsDirectives,
  getQuickGlanceInlineActionsDirectives,
  getInlineActionsDirectives,
  getSelectionRanges
};
