import logger from '../utils/logger';
import stores from '.';

const FETCH_SETTINGS = ['unresolvedBounceIndicatorFeatureAvailable'];

/**
 * Settings for user: /user/me/settings/search
 *
 */
export default class UserSettingsSearch {
  constructor(Api) {
    // setting default values
    this.settings = new Api.Users({ id: 'me' }).search;
    this.promise = null;
  }

  fetch() {
    const hasSettingsSearchApiEnabled = stores.Floodgate.hasSettingsSearchApiEnabled();
    // TODO: Remove this condition once floodgate flag dependency is eliminated. If not this feature will not be enabled in GovCloud for 15.1
    if (!hasSettingsSearchApiEnabled) {
      this.promise = Promise.resolve(this);
      return;
    }
    this.promise = this.settings
      .save({ settings: FETCH_SETTINGS })
      .then(() => {
        this.settingsAvailable = true;
        return this; // resolved value
      })
      .catch(e => {
        // the failure is caught and not thrown again, so the context board may render
        // even in this scenario.
        this.settingsAvailable = false;
        logger.warn('Unable to get user settings search', { error: e });
      });
  }

  ready() {
    return this.promise;
  }

  /*
   * Returns UNRESOLVED_BOUNCE_INDICATOR_FEATURE_AVAILABLE setting value for the user. If the setting could not be
   * fetched for the user, default to return false.
   */
  isUnresolvedBounceIndicatorFeatureAvailable() {
    return this.settings.list.getByName('unresolvedBounceIndicatorFeatureAvailable');
  }
}
